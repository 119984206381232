import * as React from "react";
import MeetOurFounderImage from "../../images/meet-our-founder.png";
import {
  OurStoryContainer,
  Content,
  Container,
  StyledButton,
  StyledText,
  StyledLink,
  FounderImageContainer,
} from "./OurStory.styles";

import { Heading } from "../Typography/Typography";
import useMediaQuery from "../../hooks/useMediaQuery";
import { size } from "../../theme/sizes";

const OurStory = () => {
  const isMobile = useMediaQuery(size.tabletL);

  return (
    <OurStoryContainer id="#our-story">
      {isMobile && <Heading weight="semiBold">Our Story</Heading>}
      <FounderImageContainer src={MeetOurFounderImage} alt="meet-our-founder" />

      <Content>
        {!isMobile && <Heading weight="semiBold">Our Story</Heading>}
        <Container>
          <StyledText>
            As a fourth-generation Kenyan, our founder, Ashish’s journey into
            entrepreneurship was driven by a passion for innovation and a desire
            to give back to his community.
          </StyledText>
          <StyledText>
            Having moved to the U.S for college and earning both his bachelor's
            and master's degrees in Computer Science, Ashish spent 15 years in
            New York working in various roles across different industries. But
            despite the successes and experiences he gained, Ashish felt a
            calling to return home to Kenya in 2020.
          </StyledText>
          <StyledText>
            Ashish then joined Antler Nairobi, a tech accelerator program
            fostering innovation and collaboration among aspiring entrepreneurs.
            It was at Antler that Ashish realized a significant gap in the
            market: many emerging tech companies lacked the technical expertise
            needed to thrive. Ashish saw an opportunity to bridge this gap by
            founding alphathesis.
          </StyledText>
          <StyledText>
            At alphathesis, Ashish seeks to empower both startups and aspiring
            engineers, fostering a culture of innovation and growth in Kenya's
            tech ecosystem. Every placement represents not just a job but a
            chance for someone to fulfill their potential and contribute to our
            collective success.
          </StyledText>
        </Container>
        <Container>
          <StyledText>Our vision at alphathesis is twofold:</StyledText>

          <StyledText>
            To provide essential technical support to burgeoning startups.{" "}
          </StyledText>
          <StyledText>
            To create opportunities for the next generation of engineers in
            Kenya.
          </StyledText>

          <StyledText>
            This dual mission resonated deeply with Ashish, reflecting his
            belief in the power of education and opportunity to transform lives
            through alphathesis.
          </StyledText>
        </Container>
      </Content>
    </OurStoryContainer>
  );
};

export default OurStory;
