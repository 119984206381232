import React from "react";
import { StyledAnchor } from "../HireTalent/HireTalent.styles";
import {
  JoinUsContainer,
  StyledButton,
  StyledText,
  ButtonContainer,
} from "./JoinUs.styles";

const JoinUs = () => (
  <JoinUsContainer id="#careers">
    <StyledText weight="semiBold">Interested in Joining Us?</StyledText>
    <StyledText>
      Our internal team is constantly innovating and growing, and we’re having
      fun while doing it. Check out our open positions.
    </StyledText>
    <ButtonContainer>
      <StyledAnchor
        target="_blank"
        href="https://www.linkedin.com/company/alphathesisllc/jobs"
      >
        <StyledButton label="See Openings" weight="semiBold" />
      </StyledAnchor>
    </ButtonContainer>
  </JoinUsContainer>
);

export default JoinUs;
