import * as React from "react";
import Layout from "../components/Layout/Layout";

import Footer from "../components/Footer";
import ScrollHandler from "../components/ScrollHander";
import OurStory from "../components/OurStory";
import OurPartnership from "../components/OurPartnership/OurPartnership";
import JoinUs from "../components/JoinUs";

const AboutUsPage = () => (
  <>
    <ScrollHandler />
    <Layout>
      <OurStory />
      <OurPartnership />
      <JoinUs />
      <Footer />
    </Layout>
  </>
);

export default AboutUsPage;
