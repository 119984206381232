import styled from "styled-components";
import { device } from "../../theme/sizes";
import Button from "../Button/Button";
import { StyledParagraph } from "../Button/Button.styles";
import { Paragraph } from "../Typography/Typography";

export const JoinUsContainer = styled.div`
  padding: 84px 106px;
  background-color: #1b2a4e;
  text-align: center;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 60px 40px;
    text-align: left;
    margin: 0 -32px;
  }
`;

export const StyledText = styled(Paragraph)`
  max-width: 1016px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.white};
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 32px;

  @media ${device.tabletL} {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  margin: 0 1em 0 0;
  ${StyledParagraph} {
    color: ${({ theme }) => theme.colors.primaryBlack};
    font-size: 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media ${device.tabletL} {
    flex-direction: column;
  }
`;
