import { Heading, Paragraph } from "../Typography/Typography";
import styled from "styled-components";
import { StyledParagraph } from "../Button/Button.styles";
import Button from "../Button/Button";
import { device } from "../../theme/sizes";
import { Link } from "gatsby";

export const HireTalentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 90px 0;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 0;
    padding-bottom: 60px;
  }
`;

export const MobileHeading = styled(Heading)`
  font-size: 32px;
  line-height: 40px;
`;

export const HireTalentImageContainer = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 572px;
  @media ${device.tabletL} {
    height: auto;
  }
`;

export const PerkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin: 24px 0;
  @media ${device.tabletL} {
    margin: 0;
  }
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;

  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

export const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  flex-grow: 1;
`;

export const Icon = styled.img`
  width: 24px;
  margin-right: 12px;
`;

export const Subtitle = styled(Paragraph)`
  display: flex;
  margin-bottom: 8px;
`;

export const Description = styled(Paragraph)`
  padding-right: 1em;
  font-size: 16px;
  line-height: 26px;
`;

export const StyledAnchor = styled.a`
  @media ${device.tabletL} {
    margin-top: 24px;
    display: block;
    width: 100%;
    text-align: center;
  }
`;

export const StyledLink = styled(Link)`
  margin: 0;
  text-decoration: none;
  color: inherit;

  @media ${device.tabletL} {
    margin: 1em auto;
  }
`;

export const StyledButton = styled(Button)`
  width: 160px;

  ${StyledParagraph} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${device.tabletL} {
    width: 155px;
    padding: 10px 0;
    margin-top: 0;

    ${StyledParagraph} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
