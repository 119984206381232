import styled from "styled-components";
import { device } from "../../theme/sizes";
import Button from "../Button/Button";
import { StyledParagraph } from "../Button/Button.styles";
import { Paragraph } from "../Typography/Typography";
import { Link } from "gatsby";

export const OurStoryContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 32px;
  padding: 90px 0;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 60px 0;
    text-align: left;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 32px;
  padding: 90px 0;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 60px 0;
    text-align: left;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  max-width: 598px;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 24px;
`;

export const StyledText = styled(Paragraph)`
  @media ${device.tabletL} {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const StyledAnchor = styled.a`
  @media ${device.tabletL} {
    margin-top: 12px;
    display: block;
    width: 100%;
    text-align: center;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const StyledButton = styled(Button)`
  ${StyledParagraph} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${device.tabletL} {
    width: 160px;
    padding: 10px 0;

    ${StyledParagraph} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const FounderImageContainer = styled.img`
  // width: 500px;
  height: fit-content;
  @media ${device.tabletL} {
    width: auto;
  }
`;
