import * as React from "react";
import OurPartnershipImage from "../../images/our-partnership.svg";
import Hand from "../../images/hand.svg";
import Code from "../../images/code.svg";
import {
  OurPartnershipContainer,
  OurPartnershipImageContainer,
  PartnerContainer,
  CardContainer,
  StyledButton,
  Container,
  Icon,
  StyledHeading,
  ContentContainer,
  StyledText,
  StyledAnchor,
} from "./OurPartnership.styles";
import useMediaQuery from "../../hooks/useMediaQuery";
import { size } from "../../theme/sizes";

const OurPartnership = () => {
  const isMobile = useMediaQuery(size.tabletL);

  return (
    <OurPartnershipContainer id="#community">
      {isMobile && <StyledHeading weight="semiBold">Community</StyledHeading>}
      <OurPartnershipImageContainer
        src={OurPartnershipImage}
        alt="out-partnership"
      />

      <PartnerContainer>
        {!isMobile && (
          <StyledHeading weight="semiBold">
            Our partnership with CodeYetu
          </StyledHeading>
        )}
        <ContentContainer>
          <StyledText>
            We are also thrilled to be partnering with CodeYetu, an incredible
            community-based organization that is taking philanthropy to the next
            level.
          </StyledText>
          <CardContainer>
            <Container>
              <Icon src={Code} alt="icon" />
              <StyledText>
                Their mission is to inspire and support children in children's
                homes across Kenya by teaching them how to code.
              </StyledText>
            </Container>

            <Container>
              <Icon src={Hand} alt="icon" />
              <StyledText>
                By giving back to their local communities and having a social
                impact program that connects youth with technology, we believe
                that everyone should be afforded the opportunity for success
                regardless of their circumstances.
              </StyledText>
            </Container>
          </CardContainer>
          <StyledText weight="mediumItalic">
            Together, we can create a future where our children’s generation has
            more tools to achieve their dreams.
          </StyledText>
        </ContentContainer>
        <StyledAnchor target="_blank" href="https://codeyetu.org">
          <StyledButton
            label="Learn More"
            variant="outline"
            weight="semiBold"
          />
        </StyledAnchor>
      </PartnerContainer>
    </OurPartnershipContainer>
  );
};

export default OurPartnership;
